@import '~styles/global';

.Container {
  color: #fff;
  background:
    linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(images/umpire.jpg) no-repeat center center fixed;
  display: flex;
  align-items: center;
  justify-content: center;

  // TODO cleaner solutions for these:

  // fill screen minue header and footer heights
  min-height: calc(100vh - 139px - 165px);
  @include medium {
    min-height: calc(100vh - 115px - 230px);
  }

  // inverse of footer margin
  margin-bottom: -10px;
  @include medium {
    margin-bottom: -50px;
  }
  @include large {
    margin-bottom: -100px;
  }
}

.Text {
  display: flex;
  flex-direction: column;
  gap: 25px;
  max-width: 80%;
  text-align: center;
  align-items: center;
}

.Title {
  @include font(36px, BOLD);

  @include medium {
    @include font-size(80px);
  }
}

.Subtitle {
  @include font(24px, BOLD);

  @include medium {
    @include font-size(30px);
  }
}
